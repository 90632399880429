.SettingsElement button {
    margin-left: 15px;
}

.SettingsElement.Small .DropdownLabel {
    left: 6px;
}

.SettingsElement {
    width: 150px;
    height: 34px;
    display: inline-block;
    margin-right: 15px;
    margin-left: 15px;
    position: relative;
    margin-top: 8px;
    margin-bottom: 5px;
}

.SearchIcon {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.PopupSearchResults {
    background-color: #1a395266;
    height: 660px;
}

.PopupSearchResults .SearchResults.Fixed .AssetList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.LabelBox {
    position: relative;
}

.LabelBox .DropdownLabel {
    top: -10px;
}

.SettingsElement.Small {
    width: 150px;
    display: inline-flex;
    margin: 10px auto 5px auto;
    position: relative;
    padding-left: 7px;
}

.SettingsElement.Hidden {
    display: none;
}

.Dropdown-option {
    font-size: 0.8rem;
}

.Dropdown-option.is-selected {
    background-color: #1a1a1a;
    color: #f6c30d;
    font-size: 14px;
    font-weight: bold;
}

.Dropdown-option {
    color: #f6c30d;
}

.Dropdown-placeholder {
    position: relative;
    min-width: 115px;
    height: 16px;
}

.Dropdown-option:hover {
    background-color: #1a1a1a;
    font-weight: bold;
}

.SortButtons {
    width: 20px;
    display: flex;
    flex-wrap: wrap;
}

.SortButtons button {
    border: none;
    outline: none;
}

.AscButton.outer {
    background-color: transparent;
    border: none;
    height: 15px;
    width: 15px;
    cursor: pointer;
    opacity: 0.85;
    margin: 0;
}

.AscButton.outer.active {
    opacity: 1;
}

.AscButton.inner {
    border-color: transparent transparent #fefefe;
    background-color: transparent;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px;
    content: ' ';
    display: block;
    cursor: pointer;
}

.AscButton.outer:hover {
    opacity: 1;
}

.AscButton.active {
    border-color: transparent transparent #f6c30d;
    cursor: auto;
}

.AscButton:hover {
    border-color: transparent transparent #f6c30d;
}

.DescButton.outer {
    background-color: transparent;
    border: none;
    height: 15px;
    width: 15px;
    cursor: pointer;
    opacity: 0.85;
    margin: 0;
}

.DescButton.outer.active {
    opacity: 1;
}

.DescButton.inner {
    position: relative;
    border-color: #fefefe transparent transparent;
    background-color: transparent;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    cursor: pointer;
}

.DescButton.outer:hover {
    opacity: 1;
}

.DescButton.active {
    border-color: #f6c30d transparent transparent;
    opacity: 1;
    cursor: auto;
}

.DescButton:hover {
    border-color: #f6c30d transparent transparent;
}

.SettingsElement input {
    border: none;
    display: -moz-box;
    display: flex;
    border-bottom: 1px solid #f6c30d;
    height: 1.2rem;
    width: 138px;
    font-size: 0.8rem;
    line-height: 1.2rem;
    margin: calc(35px - 1.2rem) auto auto auto;
    background: none;
    color: #f6c30d;
    outline: none;
    border-radius: 0;
    position: relative;
    top: 0.1rem;
}

@keyframes color-transition {
    from {
        background-color: #1a395299;
    }
    to {
        background-color: #1a395200;
    }
}

.FilterElement {
    background-color: #1a395200;
    animation-name: color-transition;
    animation-duration: 1s;
}

.SettingsElement input:disabled,
.SettingsElement.Small input:disabled {
    border-bottom: 1px solid #fefefe;
    opacity: 0.85;
}

.Dropdown-root {
    display: block;
    height: 2.5rem;
    justify-content: center;
    margin: 0 0 auto 0;
}

@media only screen and (max-width: 1000px) {
    .Dropdown-root {
        margin: auto 0 auto 0;
    }
}

.Dropdown-arrow {
    border-color: #f6c30d transparent transparent;
}

.Dropdown-control {
    background: none;
    border: none;
    border-bottom: 1px solid #f6c30d;
    border-radius: 0;
    padding-left: 0;
    color: #f6c30d;
    text-align: left;
    margin: auto;
}

.Dropdown-control.Dropdown-disabled {
    color: #fefefe;
    border-bottom: 1px solid #fefefe;
    opacity: 0.666;
}

.Dropdown-control.Dropdown-disabled .Dropdown-arrow-wrapper .Dropdown-arrow {
    border-color: #fefefe transparent transparent;
    opacity: 0.666;
}

.Dropdown-menu {
    background-color: #1a1a1a;
    border: none;
    top: 35px;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    max-height: 200px;
    z-index: 40;
    text-align: left;
    font-size: 12pt;
}

.SettingsElement.Small .Dropdown-menu {
    width: 130px;
}

.Label {
    height: 25px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
    margin: 3px;
    margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:focus {
    outline: 0;
}

button:focus {
    outline: 0;
}

input:checked + .slider {
    background-color: #f6c30d;
}

input:focus + .slider {
    box-shadow: 0 0 1px #f6c30d;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}

@media only screen and (max-width: 1000px) {
    .AssetList {
        justify-content: center;
    }

    .SettingsElement.Small {
        height: 30px;
        flex-wrap: wrap;
        margin: 0;
        position: relative;
        justify-content: center;
    }

    .SortButtons {
        margin: 0;
    }

    .SettingsElement button {
        margin-left: 28px;
    }

    .SettingsElement {
        height: 40px;
        flex-wrap: wrap;
        margin: 0;
    }

    .SettingsElement input {
        height: 30px;
        font-size: 10pt;
        margin-top: 0;
        position: relative;
        top: 0;
        left: 0;
    }

    .Dropdown-control {
        height: 30px;
        margin-top: 0;
        position: relative;
        top: 0;
        left: 0;
        font-size: 12px;
    }

    .Dropdown-menu {
        max-height: 150px;
        font-size: 12px;
    }
}
