.App {
    background-color: #181818;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;
    position: fixed;
    height: 100%;
    color: #f6c30d;
}

.Maintenance {
    font-size: 2rem;
    color: white;
    height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;

    color: #f6c30d;
}

/* Track */
::-webkit-scrollbar-track {
    background: black;
    width: 50%;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f6c30d;
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.InsideScroll::-webkit-scrollbar {
    width: 5px;
}

.InsideScroll::-webkit-scrollbar-thumb {
    background: #f6c30d;
}

.InsideScroll::-webkit-scrollbar-track {
    background: #f6c30d77;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;
}

.InsideScrollApp::-webkit-scrollbar {
    width: 5px;
}

.InsideScrollApp::-webkit-scrollbar-thumb {
    background: #f6c30d;
}

.InsideScrollApp::-webkit-scrollbar-track {
    background: #f6c30d77;
    border-left: 2px solid #181818;
    border-right: 2px solid transparent;
    background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ViewImage {
    cursor: pointer;
    width: 33px;
    height: 33px;
    margin-top: auto;
    opacity: 0.85;
}

.ViewImage:hover {
    opacity: 1;
}

.ViewImage img {
    width: 33px;
    height: 33px;
}

model-viewer {
    width: 100%;
    height: auto;
    min-height: 400px;
    max-width: 100%;
    max-height: 600px;
    outline: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

@font-face {
    font-family: 'SFCompact';
    src: url('/fonts/SF-Compact-Rounded-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'SFCompactLight';
    src: url('/fonts/SF-Compact-Rounded-Light.otf') format('opentype');
}

@font-face {
    font-family: 'SFCompactBold';
    src: url('/fonts/SF-Compact-Rounded-Bold.otf') format('opentype');
    font-weight: bold;
}

body {
    font-family: 'SFCompactLight', sans-serif;
    background-color: #000;
}

body:before {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

body:after {
    background-image: url('/background3.svg');
    z-index: 10;
    backdrop-filter: blur(20);
}

.AssetListSettings {
    text-align: right;
}

.AssetListSettings .SettingSwitch {
    text-align: right;
    display: block;
    margin-right: 20px;
    position: absolute;
    left: calc(100% - 75px);
    top: 85px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }

    .App {
        width: 100%;
        left: 0;
    }
}

.AppLink {
    display: block;
    margin: 20px;
}

.StoresAndApps {
    height: 95%;
    overflow-y: auto;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.PriceInfo {
    height: 36px;
    font-weight: 100;
    margin-top: auto;
    font-size: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    color: #fefefe;
}

.PriceInfo a {
    color: #fefefe;
}

.PageComponent {
    padding: 15px;
    margin: 15px;
}

.AssetBundle {
    display: flex;
    overflow-x: auto;
}

.BundleItemDetails {
    display: block;
    width: 250px;
}

.AssetFavButton {
    position: absolute;
    top: 55px;
    left: 239px;
    width: 36px;
    height: 39px;
}

.AssetFavButton img {
    position: absolute;
}

.AssetFavButton img:first-child {
    width: 36px;
    height: 39px;
}

.AssetFavButton img:nth-child(2) {
    width: 22px;
    height: 22px;
    top: 9px;
    left: 25px;
}

.AssetInspectButton img {
    position: absolute;
}

.AssetInspectButton img:first-child {
    width: 36px;
    height: 39px;
}

.AssetInspectButton img:nth-child(2) {
    width: 20px;
    height: 20px;
    top: 9px;
    left: 25px;
}

.AssetPreview.Small {
    height: 350px;
}

.AssetPreview table {
    margin-top: auto;
    margin-bottom: auto;
}

.SalesDate {
    display: block;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: -16px;
    font-size: 12px;
    color: #fefefe;
}

.AssetLinks .SalesDate {
    position: relative;
    top: 0;
}

.ImageContainer {
    display: flex;
    margin: auto;
}

.RankingInfo img {
    max-height: 100px;
}

.AssetPreviewDetails {
    height: auto;
    text-align: center;
    font-size: 8pt;
    color: #fefefe;
}

.CardInfo {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    height: 30px;
    margin: auto;
}

.HoverTip {
    position: absolute;
    height: 10px;
    top: -5px;
    left: 0;
    display: none;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 5px solid #f6c30d;
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-left: 5px solid transparent;
}

.AssetPreviewHoverDetailsTrigger {
    position: relative;
    overflow: visible;
}

.AssetPreviewEdition {
    top: -25px;
}

.AssetPreviewHoverDetailsTrigger .HoverTip {
    left: calc((266px - 10px) / 2);
    top: 20px;
}

.AssetPreviewEdition.AssetPreviewHoverDetailsTrigger .HoverTip {
    top: 0;
}

.AssetPreviewHoverDetails {
    position: absolute;
    width: 240px;
    max-height: 250px;
    overflow-y: auto;
    left: calc((266px - 250px) / 2);
    top: 35px;
    background-color: #0f0f0f;
    padding: 10px 5px;
    margin: 5px auto auto auto;
    z-index: 30;
    display: none;
    border-top: 1px solid #f6c30d;
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.AssetPreviewEdition .AssetPreviewHoverDetails {
    top: 15px;
}

.MarketInfoDetails {
    position: absolute;
    width: 90%;
    height: auto;
    left: auto;
    background-color: #0f0f0f;
    padding-left: 5%;
    padding-right: 1%;
    padding-bottom: 5%;
    margin-top: 5px;
    z-index: 30;
    display: none;
    border-top: 1px solid #f6c30d22;
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.AssetPreviewHoverDetailsTrigger:hover .AssetPreviewHoverDetails {
    display: block;
}

.AssetPreviewHoverDetailsTrigger:hover .HoverTip {
    display: block;
}

.BulkAssetPreviewHoverDetailsTrigger:hover .BulkAssetPreviewHoverDetails {
    display: block;
}

.BulkAssetPreviewHoverDetailsTrigger:hover .HoverTip {
    display: block;
}

.MarketInfoTrigger {
    font-size: 10pt;
    height: 2.3rem;
    color: #fefefe;
}

.MarketInfoTrigger:hover .MarketInfoDetails {
    display: block;
}

.BalanceDetailsTrigger {
    position: relative;
    top: -3px;
}

.BalanceDetails {
    position: absolute;
    width: 90%;
    height: auto;
    left: auto;
    background-color: #0f0f0f;
    padding-left: 5%;
    padding-right: 1%;
    padding-bottom: 5%;
    margin-top: 5px;
    z-index: 20;
    display: none;
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.BalanceDetailsTrigger:hover .BalanceDetails {
    display: block;
}

.BalanceDetails .HoverTip {
    left: 8.5rem;
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.MoreOptions .HoverTip {
    left: 128px;
    top: -20px;
    display: block;
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.MoreButton {
    width: 1.2rem;
    height: 1.2rem;
    opacity: 0.7;
    margin: auto;
    cursor: pointer;
}

.MoreButton:hover {
    opacity: 1;
}

.BulkAssetPreviewDetails td:last-child {
    color: #fefefe;
    text-align: right;
    max-width: 7rem;
    line-height: 16px;
}

.AssetPreviewDetails a {
    color: #fefefe;
}

.AssetPreviewImage a {
    color: #fefefe;
    font-size: 12px;
}

.AssetPreviewImage .LazyLoad {
    margin: auto;
    width: 200px;
    height: 200px;
}

.AssetInfoLink {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    height: 20px;
}

.InfoButton,
.ShareButton,
.FavButton,
.ToggleButton {
    width: 22px;
    height: 22px;
    opacity: 0.7;
}

.InfoButton:hover,
.ShareButton:hover,
.FavButton:hover,
.ToggleButton:hover {
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
}

.FavButton {
    position: absolute;
    margin-top: 5px;
    margin-left: calc(100% - 35px);
}

.FavButton.favored {
    opacity: 1;
}

.ShareContainer {
    position: absolute;
    margin-top: 5px;
    margin-left: calc(0px);
}

.ShareButton {
    position: absolute;
    margin-top: 5px;
    margin-left: calc(0px);
}

.AssetInfo {
    display: flex;
}

.HistoryContainer {
    width: calc(100% - 200px);
    margin-left: 100px;
    margin-right: 100px;
}

.HistoryContainer.Embed {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
}

.HistoryContainer.Embed .PriceHistory {
    padding: 0;
}

.PriceHistory {
    background-color: #333333aa;
    padding-top: 50px;
    padding-bottom: 50px;
}

.PriceHistory .highcharts-container {
    margin-top: auto;
    width: calc(100% - 200px);
}

.MoreSales {
    width: calc(100% - 200px);

    margin-left: 100px;
    margin-right: 100px;
}

.DetailsContainer {
    height: 100%;
}

.DetailsContainer a {
    color: #fefefe;
}

.AssetName,
h3 {
    text-align: left;
    font-size: 30pt;
    font-weight: lighter;
    color: white;
    margin-bottom: 2rem;
}

h2 {
    text-align: left;
    font-size: 15pt;
    font-weight: normal;
    color: white;
    margin: 1rem auto;
}

.AssetDetails {
    width: calc(50% - 100px);
    margin: 100px 100px auto 0;
    font-size: 10pt;
    overflow: auto;
    color: white;
}

.AuthorTd {
    display: inline-flex;
}

.AssetDetails .VerifiedIcon {
    margin-right: 5px;
}

.AssetDetails .VerifiedIcon img {
    width: 15px;
    height: 15px;
}

.AssetDetails td:first-child {
    text-align: left;
    width: 30%;
}

.AssetDetails h3 {
    color: white;
    text-align: left;
}

.AssetDetails td:last-child {
    text-align: right;
    max-width: 100px;
    overflow-x: auto;
    line-height: 22px;
}

.NumberOfAssets {
    display: inline-flex;
    line-height: 26px;
}

.NumberOfAssets a {
    color: white;
    text-decoration: underline;
}

.__react_component_tooltip {
    color: #fefefe;
    background: #1a1a1abb;
}

.AssetDetails table {
    width: 100%;
    margin-bottom: auto;
    margin-top: auto;
}

.AssetImage {
    position: relative;
    display: flex;
    width: calc(45% - 100px);
    margin: 100px auto;
}

.AssetImage .LazyLoadImg {
    display: flex;
    height: 580px;
    width: 100%;
}

.AssetImage img {
    max-width: 100%;
    max-height: 600px;
    margin: auto;
}

.AssetImage video {
    margin: auto;
}

.SaleContainer {
    max-height: 600px;
    overflow-y: auto;
}

.AssetNextButtons {
    width: 28px;
    height: 28px;
    border: none;
    color: #fefefe;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    font-size: 18pt;
}

.AssetLinks {
    height: 150px;
    position: relative;
    top: 15px;
    text-align: center;
}

.AssetLinks .ViewLink {
    margin: auto;
    height: 25%;
    line-height: 50px;
}

.AssetLinks .ViewLink a {
    color: #f6c30d;
}

.AssetLinks .UserArea {
    position: relative;
    margin: auto;
    height: 50%;
    top: 0;
}

.AssetLinks div:nth-child(3) {
    height: 25%;
    margin: auto;
}

.UserBalance {
    position: absolute;
    right: 10px;
    font-size: 12px;
    color: #fefefe;
    z-index: 10;
    line-height: 1;
}

@media only screen and (max-width: 1000px) {
    .HoverTip {
        left: 9.8rem;
    }

    .MoreSales {
        width: calc(100% - 50px);

        margin-left: 25px;
        margin-right: 25px;
    }

    body {
        -webkit-background-size: 1920px 1080px;
        -moz-background-size: 1920px 1080px;
        -o-background-size: 1920px 1080px;
        background-size: 1920px 1080px;
    }

    .AssetInfo {
        width: 100%;
        height: auto;
        display: block;
    }

    .AssetPreview:hover {
    }

    .AssetImage {
        width: 100%;
        height: auto;
    }

    .SubNav {
        font-size: 7pt;
    }

    .UserBalance {
        width: 50px;
        font-size: 9px;
    }

    .AssetImage img {
        width: auto;
        max-height: 600px;
        border-radius: 5px;
        margin: auto;
    }

    .AssetImage {
        margin: auto;
    }

    .DetailsContainer {
        width: 100%;
        height: 600px;
    }

    .AssetDetails {
        width: 90%;
        z-index: 10;
        margin: 100px 5%;
    }

    .highcharts-xaxis-labels text {
        visibility: hidden;
    }

    .highcharts-xaxis-labels text:last-child {
        visibility: visible;
    }

    .highcharts-xaxis-labels text:first-child {
        visibility: visible;
    }

    .AssetLinks {
        width: auto;
    }

    .TransactionList {
        width: calc(100% - 42px);
        overflow-x: auto;
    }

    .TransactionList table {
        min-width: 1000px;
    }

    .PriceHistory {
        width: 100%;
        position: relative;
    }

    .PriceHistory .highcharts-container {
        margin-top: auto;
        width: 100%;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    top: 100%;
    left: 50%;
    margin-left: -60px;

    position: absolute;
    z-index: 10;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.Analytics {
    width: 96%;
    padding-top: 10px;
    margin: auto;
    padding-left: 2%;
    padding-right: 2%;
    height: calc(100% - 140px);
}

.Stats {
    display: block;
    overflow-y: auto;
}

.CollectionContainer {
    width: 100%;
}

@media only screen and (max-width: 1000px) {
}

.RevenueChart h3 {
    margin-top: 0;
}

.RevenueChart {
    width: calc(60% - 91px);
    height: 350px;
}

.RevenueChart .highcharts-container {
    width: 100%;
    height: 270px;
}

.MarketChart h3 {
    margin-top: 0;
}

.MarketChart {
    height: 400px;
}

.highcharts-legend {
    height: 100px !important;
    overflow-y: hidden !important;
}

.TopMarkets table,
.TradingHistory table {
    margin-top: 1rem;
    border: none;
    width: 100%;
    text-align: center;
}

.TopMarkets table th,
.TradingHistory table th {
    border-bottom: 1px solid #fefefe;
    padding-bottom: 10px;
    border-spacing: 0;
}

.TopMarkets td,
.TradingHistory td {
    padding: 14px;
    border: 0;
}

.TopMarkets th:hover,
.TradingHistory th:hover {
    text-shadow: 0 0 2px #fefefe;
    cursor: pointer;
}

.TopMarkets th:first-child:hover,
.TradingHistory th:first-child:hover {
    text-shadow: none;
    cursor: auto;
}

.TopMarkets th:nth-child(2):hover,
.TradingHistory th:nth-child(2):hover {
    text-shadow: none;
    cursor: auto;
}

.TopMarkets {
    height: auto;
    overflow-y: auto;
    color: #fefefe;
}

.TradingHistory {
    height: auto;
    overflow-y: auto;
    color: #fefefe;
    background-color: #333333aa;
}

.TopCollections {
    height: auto;
    overflow-y: auto;
    color: #fefefe;
}

.TopCollections table {
    margin-top: 1rem;
    border: none;
    width: 100%;
    text-align: center;
}

.TopCollections table th {
    border-bottom: 1px solid #fefefe;
    padding-bottom: 10px;
    border-spacing: 0;
}

.TopCollections td {
    padding: 14px;
    border: 0;
}

.TopCollections th:hover {
    text-shadow: 0 0 2px #fefefe;
    cursor: pointer;
}

.TopCollections th:first-child:hover {
    text-shadow: none;
    cursor: auto;
}

.TopCollections th:nth-child(2):hover {
    text-shadow: none;
    cursor: auto;
}

.AnalyticsComponent .Dropdown-root {
    width: 25%;
    margin: auto;
}

.AnalyticsComponent .Dropdown-control {
    width: 100%;
}

.CollectionComponent .Dropdown-root {
    width: 50%;
    margin: auto;
}

.CollectionComponent .Dropdown-control {
    width: 100%;
}

.AnalyticsComponent .Dropdown-menu {
    width: 100%;
}

.AnalyticsComponent .highcharts-container {
    width: 100%;
    height: 320px;
}

@media only screen and (max-width: 1000px) {
    .AnalyticsComponent .Dropdown-root {
        width: 50%;
    }

    .RevenueChart {
        width: auto;
    }
    .MarketChart {
        width: auto;
    }

    .MarketChart .Dropdown-control {
        width: 70%;
    }

    .MarketChart .Dropdown-menu {
        width: 70%;
    }

    .TopMarkets th {
        font-size: 10pt;
    }

    .TopMarkets td {
        padding: 5px;
        border: 0;
        font-size: 10pt;
    }

    .TopCollections th {
        font-size: 10pt;
    }

    .TopCollections td {
        padding: 5px;
        border: 0;
        font-size: 10pt;
    }
}

.Growth {
    display: flex;
    justify-content: space-evenly;
}

.Growth div:first-child {
    width: 20%;
    margin: auto;
}

.Growth div:last-child {
    width: 80%;
    margin: auto;
}

.Glyph.ArrowUp {
    fill: #f6c30d;
}

.Glyph.ArrowDown {
    fill: #fefefe;
}

@media only screen and (max-width: 1000px) {
    .GeneralStats {
        color: #fefefe;
        width: auto;
        height: auto;
    }

    .GeneralStats table {
        border-spacing: 2px;
        font-size: 12px;
    }
}

.shadowed {
    box-shadow: 5px 5px 20px -5px #000000aa;
}

.AssetHeadline {
    height: 20%;
}

.Icon {
    position: relative;
    cursor: pointer;
}

.Icon img {
    height: 22px;
    width: 22px;
}

.flip-card {
    background-color: transparent;
    height: 11rem;
    width: 11rem;
    margin: 0.5rem;
    perspective: 1000px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-background {
    -webkit-filter: blur(10px);
    filter: blur(10px);
    opacity: 1;
    width: 120%;
    height: 120%;
    position: relative;
    left: -10%;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    display: flex;
}

.flip-card-back {
    width: 100%;
    height: 100%;
    transform: rotateY(180deg);
}

.Icon {
    color: #fefefe;
    text-decoration: None;
    font-size: 18px;
    height: 26px;
    opacity: 0.85;
    transition: opacity 0.5s;
    text-align: center;
}

.Icon:hover {
    color: #fefefe;
    opacity: 1;
}

.Icon.selected {
    color: #fefefe;
    opacity: 1;
}

.LoginButton {
    color: #fefefe;
    text-decoration: None;
    font-size: 18px;
    height: 30px;
    width: 30px;
    opacity: 0.85;
    background-color: transparent;
    text-align: right;
    border: none;
    outline: none;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.LoginButton:hover {
    color: #fefefe;
    opacity: 1;
    font-size: 24px;
}

.UserIcon {
    display: flex;
    margin: auto;
    text-align: center;
    font-size: 16px;
    width: 14%;
    color: #fefefe;
    padding: 2%;
    cursor: pointer;
}

.UserIcon img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: auto;
    margin-top: auto;
}

.ViewAsImage {
    color: #fefefe;
    opacity: 0.7;
}

.ViewAsImage:hover {
    color: #fefefe;
    opacity: 1;
}

@media only screen and (max-width: 1000px) {
    .ViewAsImage {
        display: none;
    }

    .flip-card.large {
        background-color: transparent;
        width: 100%;
        height: auto;
        perspective: 1000px; /* Remove this if you don't want the 3D effect */
    }

    .AssetList {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .UserIcon {
        font-size: 10px;
    }
}

.Page {
    height: calc(100% - 96px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: auto auto auto 0;
    z-index: 10;
    transition: transform 0.2s, width 0.2s;
}

.ScrollUpIcon {
    position: absolute;
    left: calc(100% - 70px);
    top: calc(100% - 70px);
}

.ScrollUpIcon img {
    width: 40px;
    height: 40px;
}

.Banners {
    text-align: center;
    width: 700px;
    height: 100px;
    margin: auto;
}

.Banners img {
    width: auto;
    max-height: 100px;
    max-width: 700px;
    margin: auto;
}

.Banners a {
    opacity: 1;
}

.CollectionElement {
    width: 95%;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 5px auto;
    flex-wrap: wrap;
    height: 100px;
}

.CollectionElement .FavoritesOnly {
    margin: auto;
}

.CollectionDropdown {
    display: flex;
    height: 44px;
    width: 100%;
}

.CollectionDropdown .MuiInputBase-fullWidth {
}

.CollectionDropdown img {
    position: absolute;
    top: 10px;
}

.BackgroundImage {
    position: absolute;
    width: 60%;
    height: 60%;
    margin: 10% 20% 20% 20%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5;
    z-index: -1;
    filter: blur(5px) brightness(0.4);
}

.AssetContent {
    position: relative;
    width: 100%;
    height: 100%;
    top: -100%;
}

.CollectionDropdown .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: None;
}

.CollectionDropdown .MuiInputBase-root {
    height: 44px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #1a395299;
    border: 3px solid #f6c30d77;
    padding: 0 5px 5px 5px;
    border-radius: 20px;
}

.CollectionDropdown .MuiChip-root {
    background-color: transparent;
    border: 1px solid #f6c30d77;
    border-radius: 15px;
}

.CollectionElement
    .CollectionDropdown
    .MuiAutocomplete-inputRoot
    .MuiAutocomplete-input:first-child {
    padding: 6px;
}

.CollectionDropdown .MuiChip-label {
    color: #f6c30d;
}

.CollectionDropdown .MuiIconButton-label {
}

.CollectionDropdownImage {
    height: 30px;
    width: 30px;
}

body .MuiPaper-root {
    font-family: sans-serif;
    background-color: #111;
    color: #fefefe;
    font-size: 12px;
}

.CollectionDropdownImage img {
    height: 30px;
    width: 30px;
}

body .MuiAutocomplete-option {
    cursor: pointer;
    display: flex;
    outline: 0;
    box-sizing: border-box;
    min-height: 30px;
    align-items: center;
    margin: 5px;
    padding: 0;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;
}

body
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot {
    padding-right: 25px;
}

.CollectionDropdown img {
    height: 30px;
    width: 30px;
    margin: auto;
    margin-right: 10px;
}

.CollectionElement .MuiAutocomplete-hasPopupIcon,
.CollectionElement .MuiAutocomplete-inputRoot {
    padding-right: 0;
}

.CollectionElement .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 0;
}

.CollectionElement .MuiFormLabel-root {
    color: #f5f5f5;
    width: 250px;
}

.CollectionElement .MuiSvgIcon-root {
    color: #f6c30d;
}

.CollectionElement .MuiFormControl-fullWidth {
    margin: auto;
}

.CollectionElement .MuiInputBase-input {
    position: relative;
    top: 2px;
    color: #f6c30d;
}

.CollectionDropdown .MuiInputBase-input input::placeholder {
    color: green;
}

.CollectionDropdown {
    max-width: 200px;
    margin: auto;
}

.CollectionDropdown.ChooseCollection .MuiInputBase-input {
    color: #f6c30d;
    opacity: 1;
}

.CollectionElement .MuiInput-underline {
}

.CollectionElement .MuiInput-underline:before {
    border-bottom: none;
}

.CollectionElement .MuiInput-underline:after {
    border-bottom: none;
}

.CollectionElement .Dropdown-control {
    width: 200px;
    height: 40px;
}

.CollectionElement .Dropdown-menu {
    width: 200px;
    top: 40px;
}

.CollectionElement .Dropdown-placeholder {
    width: 180px;
    font-size: 16px;
    font-weight: bold;
}

.CollectionElement Dropdown-option.is-selected {
    width: 180px;
    font-size: 16px;
    font-weight: bold;
}

.CollectionElement .Dropdown-option {
    font-size: 16px;
    font-weight: bold;
    height: 40px;
}

.CollectionDropdownOption.Small {
    font-size: 14px;
}

.CollectionDropdownOption {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    width: 140px;
}

.CollectionDropdownOption div {
    margin: auto;
}

.CollectionDropdownOption div:last-child {
    width: calc(100% - 20px);
    overflow-x: hidden;
    white-space: nowrap;
    padding-left: 5px;
}

@media only screen and (max-width: 1450px) {
    .CollectionElement Dropdown-placeholder is-selected {
        width: 200px;
    }
}

.Signature img {
    width: 50%;
}

@media only screen and (max-width: 1450px) {
    .Banners {
        width: 400px;
        max-height: 100px;
    }

    .Banners img {
        width: 400px;
        max-height: 100px;
    }

    .Tabs {
        position: relative;
    }

    .Tabs.Banner {
    }
}

@media only screen and (max-width: 1000px) {
    .MarketPage,
    .InventoryPage {
        height: calc(100% - 200px);
        width: 100%;
    }

    .Tabs {
    }

    .Page {
        height: calc(100% - 188px);
    }

    .Banners img {
        width: 100%;
    }

    .CollectionElement .FavoritesOnly {
        margin-top: 15px;
    }

    .CollectionElement {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .CollectionElement Dropdown-placeholder is-selected {
        width: 200px;
    }

    .ScrollUpIcon {
        position: absolute;
        left: calc(100% - 60px);
        top: calc(100% - 40px);
    }

    .TopMarkets {
        width: auto;
    }

    .TopCollections {
        width: auto;
    }

    .TopMarkets .Dropdown-control {
        width: 50%;
    }

    .TopMarkets .Dropdown-menu {
        width: 50%;
    }

    .TopCollections .Dropdown-control {
        width: 50%;
    }

    .TopCollections .Dropdown-menu {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .MoreSales {
        width: 100%;

        margin-left: 0;
        margin-right: 0;
    }
}

.MarketChart .highcharts-legend {
    overflow-y: auto;
    max-height: 50px;
    height: 100px;
}

.RefreshIcon {
    width: 32px;
    height: 32px;
}

.RefreshIcon.Loading {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: reverse;
    margin-right: auto;
    margin-left: auto;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.App-footer {
    background-color: #f6c30d;
    color: #fefefe;
    height: 5%;
    z-index: 0;
}

.TopAsset {
    display: inline-block;
    font-size: 12pt;
    overflow-wrap: break-word;
    overflow: hidden;
    background-color: #fefefe;
}

.TopAssetPrice {
    text-align: center;
    position: relative;
}

.MarketLink {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    color: #fefefe;
}

.MarketLink.Available div:last-child {
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
}

.MarketLink.Available div:first-child {
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-evenly;
}

.MarketLink.Sold {
    font-size: 10pt;
}

@keyframes bounce {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(-10px);
    }
}
@-webkit-keyframes bounce {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(-10px);
    }
}

.Arrow {
    position: relative;
    top: -1px;
}

.MarketLink:hover div.Arrow {
    margin-left: 10px;
    animation: bounce 1s infinite alternate;
    -webkit-animation: bounce 0.5s infinite alternate;
}

.ErrorNote {
    position: absolute;
    background-color: #333333;
    border-radius: 5px;
    border: 1px solid #bf0007;
    text-align: center;
    z-index: 30;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    max-width: 250px;
    max-height: 100px;
    padding: 10px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    color: #f6c30d;
    font-size: 12px;
    margin-left: auto;
    margin-right: auto;
}

.SellPriceInfo {
    font-size: 8px;
    width: 100%;
}

.UserAreaa div {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
    width: calc(100% + 20px);
}

.EditContainer {
    margin: auto;
    line-height: 1.5rem;
    font-weight: bold;
    color: #fefefe;
    display: flex;
    height: auto;
}

.UserArea.Full .BuyContainer .MoreOptions {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
}

.BuyButton.Relative {
    position: relative;
    top: 0;
    left: 0;
    background-color: transparent;
    height: 1.5rem;
}

.BuyButton.Relative a {
    color: black;
    font-weight: bold;
    font-size: 10pt;
    opacity: 1;
}

.PrevNextButtons {
    position: absolute;
    margin: auto;
    width: 28px;
    height: 28px;
    border: none;
    color: #f6c30d;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    font-size: 18px;
}

.PrevNextButtons:first-child {
    margin: 0;
    margin-right: auto;
    left: 0;
    top: 30%;
}

.PrevNextButtons:last-child {
    margin: 0;
    margin-left: auto;
    right: 0;
    top: 30%;
}

.PrevNextButtons:hover {
    font-weight: bold;
}

.BuyButton:hover {
    font-weight: bolder;
}

.UnpackButton {
    width: 150px;
    transition: width 0.25s;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    border: 1px solid #f6c30d;
    color: #f6c30d;
    cursor: pointer;
    font-size: 10pt;
    background-color: transparent;
    outline: none;
    margin: auto;
    font-weight: bold;
}

.UnpackButton:hover {
    font-weight: bold;
    box-shadow: 0 0 2px #f6c30d;
}

.UnpackButton a {
    color: #f6c30d;
    opacity: 1;
}

.SwapButton {
    width: 150px;
    transition: width 0.25s;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #f6c30d;
    color: #f6c30d;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    margin: auto;
}

.SwapButton:hover {
    font-weight: bold;
    box-shadow: 0 0 2px #f6c30d;
}

.SellInput,
.TransferInput {
}

video {
    outline: none;
}

.userInput {
    outline: none !important;
}

#webgl-canvas {
    outline: none !important;
}

.SellInput {
    border-bottom: 2px solid black;
}

.MoreOptions .SellButtonFull {
    top: 0;
    left: 0;
    position: relative;
    margin: 5px auto;
}

.SellInput.Memo {
    border-bottom: 2px solid #f6c30d;
    background-color: transparent;
    color: #f6c30d;
    min-width: 100px;
}

.TransferInput {
    font-size: 8pt;
    margin: auto;
    color: #f6c30d;
}

.AssetLinks .UserArea {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.SellInput.Hidden,
.TransferInput.Hidden {
    visibility: hidden;
    width: 0;
    transition: width 0.25s;
    margin: 0;
    padding: 0;
}

.SellInput.FadeIn {
    width: 50px;
}

.SellInput.Large {
    width: 100%;
}

.TransferInput.FadeIn {
    width: 50px;
}

.TransferContainer {
    display: flex;
    width: 100px;
    margin: auto;
}

.MoreOptions button {
    margin-bottom: 5px;
}

.MoreOptions input {
    margin-bottom: 5px;
}

.BidInput {
    margin: auto;
    border: none;
    display: flex;
    border-bottom: 1px solid #f6c30d;
    height: 30px;
    width: 0;
    transition: width 0.25s;
    font-size: 16px;
    background: none;
    color: #f6c30d;
    outline: none;
}

.BidInput.Hidden {
    visibility: hidden;
    width: 0;
    transition: width 0.25s;
}

.BidInput.FadeIn {
    width: 75px;
}

.PriceArea {
    display: flex;
    position: absolute;
    width: 16rem;
}

@media only screen and (max-width: 1000px) {
    .PriceArea {
        width: 96%;
    }

    .TopAssetPrice {
        top: -120px;
    }

    .UserArea {
        position: relative;
    }
}

.TopAssetPrice {
    color: #fefefe;
}

.NextLink {
    cursor: pointer;
}

.MarketLink {
    line-height: 40px;
    display: flex;
    font-size: 20px;
}

.MarketLink .SubNav {
    display: flex;
    font-size: 18px;
}

.MarketLink .Icon {
    top: -2px;
}

.FaqItem a {
    color: #4a82af;
    cursor: pointer;
}

.TopAsset a {
    color: #fefefe;
}

.TopAsset img {
    max-height: 100px;
    max-width: 100px;
}

.TopAssetTitle {
    height: 30px;
}

.TopAssetImage {
    height: 100px;
    margin: 20px;
}

.TopAssetDetails {
    height: 60px;
    padding: 10px;
    text-align: left;
    font-size: 8pt;
    color: #fefefe;
}

.TopAssetDetails td:first-child {
    color: #f6c30d;
}

.Asset {
    height: calc(100% - 140px);
    overflow: auto;
    padding-top: 10px;
}

@media only screen and (max-width: 1000px) {
    .Asset {
        height: calc(100% - 170px);
        overflow: auto;
        padding-top: 10px;
    }
}

.tab-pane {
    display: None;
}

.tab-pane.active {
    display: block;
    height: 100%;
}

.TransactionList {
    background-color: #333333aa;
    padding-top: 50px;
    padding-bottom: 50px;
}

.TransactionInfo:nth-child(even) {
    background-color: rgba(20, 20, 20, 0.5);
}

.TransactionInfo td {
    text-align: center;
}

.TransactionInfo td:last-child {
    text-align: center;
}

.TransactionList a {
    color: #fefefe;
}

.TransactionList p {
    font-weight: bold;
}

.TransactionList table {
    border: none;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .CollectionElement .MuiAutocomplete-hasPopupIcon,
    .CollectionElement .MuiFormControl-fullWidth .MuiAutocomplete-inputRoot {
        padding-right: 0;
    }

    .AssetTabs {
        margin: 5px;
        padding-right: 2px;
        padding-left: 2px;
        font-size: 12px;
    }

    .AssetTabs a {
        color: #fefefe;
        text-decoration: None;
        padding: 10px;
        padding-left: 5px;
        padding-right: 5px;
        opacity: 0.85;
        outline: 0;
    }
}

tspan {
    color: #f6c30d;
    font-weight: bold;
}

.css-49imk9 {
    position: relative;
    left: 100px;
    top: -150px;
    display: inline-block;
}

.FindButton {
    margin-top: 20px;
}

@media only screen and (max-width: 1000px) {
    .AssetSearch {
        width: 100%;
    }

    .AssetList {
        display: block;
        height: fit-content;
    }

    .AssetPreviewDetails {
        text-align: center;
        font-size: 10pt;
        color: #fefefe;
    }

    .InfoButtonLink {
        margin-left: calc(50% - 30px);
    }

    .AssetSearch .SearchSettings {
        width: 100%;
        min-height: 0;
        height: fit-content;
        z-index: 20;
    }

    .AssetSearch .SearchSettings .SettingsButton {
        width: 0;
        height: 0;
        display: none;
    }

    .AssetSearch .SearchSettings .CloseButton {
        width: 0;
        height: 0;
        display: none;
    }

    .InfoButton {
        width: 18px;
        height: 18px;
    }

    .PageComponent {
        margin: 0;
        margin-bottom: 5px;
        padding: 5px;
    }

    .EditContainer {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 1000px) {
    .HistoryContainer {
        width: 100%;
        margin: 0;
    }

    .Page {
        padding: 0;
    }

    .PageComponent {
        padding: 0;
    }
}
