.LanguagePicker {
    position: absolute;
    width: 11rem;
    display: flex;
    flex-wrap: wrap;
    top: 1.5rem;
    background-image: linear-gradient(to bottom left, #1a3952dd 50%, #472a00ee);
    border-radius: 1rem;
    z-index: 70;
}

.FlagIcon {
    margin: auto 0.5rem;
}

.LanguageElement {
    position: relative;
    height: 26px;
    width: 20px;
    margin: auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
}

.LanguageElement:hover {
}

.LanguageElement .Dropdown-root {
    display: block;
    justify-content: normal;
    width: 20px;
}

.LanguageElement .Dropdown-arrow {
    display: none;
}

.LanguageElement .Dropdown-placeholder {
    min-width: 20px;
    max-width: 20px;
    top: 0;
}

.LanguageElement .Dropdown-control {
    height: 21px;
    width: 20px;
    font-size: 14px;
    border-bottom: 1px solid #fefefe;
    border-radius: 0;
    color: #fefefe;
    text-align: center;
    padding: 0;
}

.LanguageElement .Dropdown-menu {
    background-color: #1a1a1a;
    border: none;
    width: 60px;
    top: 22px;
    left: -20px;
    z-index: 20;
    text-align: center;
    color: #fefefe;
    font-size: 14px;
}

.LanguageElement {
    font-size: 10pt;
}

.LanguageElement .Dropdown-option {
    padding: 0;
    height: 18px;
    font-size: 10px;
    line-height: 18px;
    color: white;
    background-color: #1a3952;
}

.LanguageElement .Dropdown-option:nth-child(3) {
    font-size: 10px;
}

.LanguageElement .Dropdown-option:nth-child(4) {
    font-size: 10px;
}

.LanguageElement .Dropdown-option:nth-child(5) {
    font-size: 10px;
}
