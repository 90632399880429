.CollectionRelease {
    text-align: center;
    display: flex;
    font-size: 12pt;
    overflow-wrap: break-word;
    margin: 1rem 1rem 2rem 1rem;
    padding: 0.3rem;
    width: 13.5rem;
    min-width: 13.5rem;
    height: 13rem;
    box-shadow: 5px 5px 20px -5px #000000aa;
    border-radius: 1rem;
    background-color: #262626bb;
    backdrop-filter: blur(2px);
    position: relative;
}

.ReleaseContent {
    margin: auto;
    color: white;
}

.ReleaseHeader {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #f6c30d;
}

.ReleaseImage img {
    max-width: 5rem;
    max-height: 5rem;
}
