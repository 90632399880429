.TradeLoads {
    position: absolute;
    left: calc(50% - 25px);
    top: 65px;
    right: auto;
}

.RightLoader {
    position: relative;
    left: -21%;
}

.TradeButtons .TradeButton {
    width: 150px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #f6c30d;
    color: #f6c30d;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    margin: 5px;
    position: relative;
}

.TradeButton.Decline {
    border: 1px solid #c70009;
    color: #c70009;
}

.TradeItem {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    height: auto;
}

.TradeMemo {
    position: relative;
    width: 80%;
    height: 2rem;
    max-height: 2rem;
    font-size: 10pt;
    line-height: 1rem;
    font-weight: normal;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1000px) {
    .TradeButtons {
        top: -3rem;
    }
}

@media only screen and (max-width: 768px) {
    #TradePage {
        overflow-x: auto;
    }
}
